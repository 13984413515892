<template>
  <div class="review-container">
    <div
      style="
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 14px 12px;
        background-color: #f6f6f6;
      "
    >
      <router-link to="/" class="back-btn">
        <v-btn
          icon
          class="back-btn"
          style="background-color: white; padding: 4px; color: #000 !important;font-size:16px !important;"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </router-link>

      <h4 style="font-size: 16px !important; font-weight: 600 !important">
        {{ $t("Bali Trip Center Review") }}
      </h4>
    </div>
    <div class="review-section-title">
      <h4 class="subheader">{{ $t("What they say about") }}</h4>
      <h1 class="header">Bali Trip Center</h1>
    </div>
    <div v-if="!loading" style="padding-top: 15px">
      <div v-if="items && items.length">
        <v-container fluid grid-list-md pa-1>
          <v-layout
            :class="{
              'row wrap': isAllReviews,
              'scrolling-wrapper': !isAllReviews,
            }"
          >
            <v-flex
              v-for="(post, i) in items"
              :key="i"
              :xs12="isAllReviews"
              :sm6="isAllReviews"
              :xl4="!isAllReviews"
              class="card"
            >
              <home-review-card
                :post="post"
                :isAllReview="true"
                :newLayout="true"
                :index="i"
                :noBorder="true"
              ></home-review-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>



      <loading :loading="loading"></loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      items: [],
      stats: [],
      errors: [],
      isAllReviews: true, // State to manage layout toggle
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/comments`)
        .then((response) => {
          this.loading = false;
          this.stats = response.data.data;
          this.items = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    toggleLayout() {
      this.isAllReviews = !this.isAllReviews; // Toggle layout between slider and grid-column
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.header {
  font-size: 25px;
  font-weight: 700;
}

.review-container {
  position: relative;
  padding: 0 12px;
}

.review-section-title {
  padding: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #e5f3fe;
  position: relative;
  top: 0px;
  left: -14px;
  width: calc(100% + 28px);
  background: url("~@/assets/images/banner-review.png") no-repeat bottom center;
  background-size: cover;
  margin-bottom: 30px;
}

.review-total-score {
  justify-content: space-between;
  margin-bottom: 10px;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 30px 0 14px;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 30px;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.row.wrap .card {
  width: 100%; /* Full width for grid layout */
  margin-bottom: 16px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}

.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
</style>
