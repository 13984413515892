<template>
  <div>
    <v-card
      elevation="4"
      class="rounded black--text card-container"
      :to="{
        name: 'activity',
        params: { slug: post.slug, type: post.type.slug },
      }"
    >
      <!-- Thumbnail Slider -->
      <div class="image-container">
        <VueSlickCarousel v-bind="sliderSettings">
          <div v-for="(image, index) in post.images" :key="index">
            <v-img
              :src="image.thumbnail"
              :alt="'Image ' + index"
              height="200px"
              width="100%"
              class="img-card"
              sty
            ></v-img>
          </div>
        </VueSlickCarousel>

        <!-- Rating (bottom-left) -->
        <div
          class="badge"
          v-if="
            post.label_detail || (language === 'id' && post.label_detail_id)
          "
          :style="{
            backgroundColor:
              language === 'id' ? post.label_color_id : post.label_color,
          }"
        >
          {{ post.label_detail }}{{ language === "id" ? "_id" : "" }}
        </div>
      </div>

      <v-card-text style="margin-top: -14px">
        <b
          class="title black--text"
          style="font-weight: 700; font-size: 16px !important"
          >{{ post.name }}</b
        >
        <br />

        <!-- create badge bg $fdfdfd location, duration, minimum person -->
        <div style="display: flex; gap: 8px; margin-top: 8px">
          <div
            style="
              color: #757575;
              background-color: #f5f5f5;
              padding: 2px;
              border-radius: 5px;
              max-width: max-content;
            "
          >
            {{ post.location }}
          </div>
          <div
            style="
              color: #757575;
              background-color: #f5f5f5;
              padding: 2px;
              border-radius: 5px;
              max-width: max-content;
            "
          >
            {{ post.required }} Person
          </div>
          <div
            style="
              color: #757575;
              background-color: #f5f5f5;
              padding: 2px;
              border-radius: 5px;
              max-width: max-content;
            "
          >
            {{ post.duration }} Hours
          </div>
        </div>

        <div
          style="display: flex; gap: 8px; margin-top: 8px; align-items: center"
        >
          <div class="rating-overlay">
            <b>
              {{ post.rating }}
            </b>
            <v-icon size="14" style="color: orange">mdi-star</v-icon>
            (
            {{ post.comments_count }}
            )
          </div>

          <span style="color: #757575; font-size: 12px">
            ({{ post.jumlah_book }} booked)
          </span>
        </div>

        <div class="short_des">{{ post.short_des }}</div>

        <div class="d-flex justify-space-between align-center">
          <div style="display: flex; gap: 5px; align-items: flex-start">
            {{ $t("from") }}
            <div>
              <small class="price-orange">
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
              </small>
              <span class="price-orange">
                {{
                  $root.$i18n.locale == "id"
                    ? post.price_idr
                    : post.price_usd | fm
                }}
              </span>
            </div>
          </div>
          <!-- show list 3 images width height 40px object cover  gap-10px-->
          <div style="display: flex; gap: 10px; margin-top: 8px">
            <v-img
              v-for="(image, index) in post.images.slice(0, 3)"
              :key="index"
              :src="image.thumbnail"
              :alt="'Image ' + index"
              height="40px"
              width="40px"
              class="img-card"
            ></v-img>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  props: ["post"],
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      language: localStorage.getItem("preferredLanguage"),
      sliderSettings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
  created() {
    this.language = localStorage.getItem("preferredLanguage");
  },
};
</script>

<style scoped>
.card-container {
  position: relative;
}

.v-responsive {
  border-radius: 10px;
}

.image-container {
  position: relative;
}

.overlay-top-left {
  position: absolute;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.overlay-bottom-left {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  padding: 4px 8px;
  border-radius: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.rating-overlay {
  background-color: rgba(255, 255, 255, 1);
  padding: 4px 8px;
  border-radius: 100px;
  font-size: 12px;
  border: 1px solid #d9d9d9;
  max-width: max-content;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.overlay-bottom-right {
  position: absolute;
  background-color: #000;
  padding: 8px 12px;
  color: #fff;
  font-size: 12px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  flex-direction: column;
}

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 9px;
  text-transform: uppercase;
  z-index: 4;
}

/* custom the dots to abslute so can setting the position and make it the gap every dot is 5px */
:deep(.slick-dots.custom-dot-class) {
  position: relative;
  bottom: 30px;
  margin: 0 auto;
  width: max-content;
  display: flex !important;
  align-items: center;
  gap: 5px;
  list-style: none !important;
  padding: 0;
  /* margin: 0; */
}

/* Custom dot styles */
:deep(.slick-dots.custom-dot-class li) {
  width: 12px;
  height: 12px;
  list-style-type: none !important;
  margin: 0 4px;
  position: static;
}

/* Customize the active dot */
:deep(.slick-dots.custom-dot-class li.slick-active button) {
  background-color: #5c5c5c !important; /* Change active dot color */
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

/* Change non-active dot color */
:deep(.slick-dots.custom-dot-class li button) {
  background-color: #d9d9d9 !important; /* Default dot color */
  border-radius: 50%;
  width: 9px;
  height: 9px;
  border: none;
}

:deep(.slick-dots.custom-dot-class li button::before) {
  content: "";
}

/* Optional: Hover effect for dots */
:deep(.slick-dots.custom-dot-class li button:hover) {
  background-color: #ffcc00 !important;
}

.overlay-top-left {
  top: 8px;
  left: 8px;
}

.overlay-bottom-left {
  top: 22px;
  left: 8px;
}

.overlay-bottom-right {
  bottom: 14px;
  right: 0;
}

.rating {
  background-color: black;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 20px;
  width: 60px;
  font-size: 12px;
  max-width: 60px;
  margin-right: 4px;
}

.price-orange {
  font-size: 16px;
  font-weight: 700;
}

.rounded {
  border-radius: 14px !important;
}

.short_des {
  line-height: normal;
  padding: 8px 0;
  border-radius: 8px;
}

/* create overlay image gradient #000 to transparent from top to bottom */
/* .img-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.52) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-bottom-left-radius: 30px;
} */
</style>
