<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click.native="cancelComment">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("comment-form") }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <div style="text-decoration: none; color: inherit" class="card-custom2">
          <v-img
            class="card-img-custom"
            :src="post?.images[0]?.thumbnail"
          ></v-img>
          <div>
            <h4
              style="
                font-size: 14px;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ $t("please-write-comment") }} {{ post.name }}
            </h4>

            <p
              style="
                font-size: 12px;
                margin-top: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 700;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              "
            >
              {{ post.location }}
            </p>
          </div>
        </div>

        <h2 style="font-size: 14px; font-weight: 700">
          {{ $t("form-comment-title") }}
        </h2>

        <v-form class="mt-4" ref="form" v-model="valid" lazy-validation>
          <div class="custom-input">
            <label for="name">{{ $t("name") }}</label>
            <input
              id="name"
              type="text"
              v-model="name"
              placeholder="write your name here"
              required
            />
          </div>

          <div class="custom-input">
            <label for="date">{{ $t("select-date") }}</label>
            <div class="date-picker">
              <v-icon>mdi-calendar</v-icon>
              <input
                id="date"
                type="text"
                v-model="date"
                placeholder="Select Date"
                readonly
                @click="datePicker = true"
                required
              />
            </div>
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <v-date-picker
                v-model="date"
                @input="datePicker = false"
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="custom-input">
            <label for="title">{{ $t("comment-title") }}</label>
            <input
              id="title"
              type="text"
              v-model="title"
              placeholder="write title max 54 karakter"
              required
            />
          </div>

          <span class="captions" style="font-weight: 700">{{
            $t("rating")
          }}</span>
          <v-rating
            v-model="starRating"
            background-color="gray"
            color="#ff6100"
            size="30"
          ></v-rating>

          <div class="custom-input">
            <label for="body">{{ $t("comment-body") }}</label>
            <textarea
              id="body"
              v-model="body"
              placeholder="Create your Comentary"
              required
            ></textarea>
          </div>

          <div class="custom-input" style="max-width: max-content !important">
            <label for="file">{{ $t("upload-photo") }}</label>
            <div class="file-input" @click="triggerFileInput">
              <img src="@/assets/images/upload-new.svg" alt="Upload Icon" />
              <input
                id="file"
                type="file"
                accept="image/*"
                ref="fileInput"
                @change="previewImages"
                multiple
              />
            </div>
          </div>

          <div
            style="display: flex; flex-wrap: wrap; gap: 14px; margin-top: 10px"
          >
            <div
              v-for="(image, index) in imagePreviews"
              :key="index"
              style="position: relative; width: 100px; height: 100px;border-radius: 10px; border:1px solid #ccc"
            >
              <img
                :src="image"
                style="width: 100%; height: 100%; object-fit: cover"
              />
              <v-btn
                icon
                small
                style="
                  position: absolute;
                  top: -10px;
                  right: -10px;
                  background-color: white;
                  border: 1px solid #ccc;
                "
                @click="deleteImage(index)"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>

          <v-btn
            color="green"
            block
            class="white--text mt-3"
            :disabled="!valid"
            :loading="submitLoading"
            @click="postComment"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: ["dialog", "url", "post"],
  data() {
    return {
      submitLoading: false,
      valid: false,
      name: "",
      date: "",
      datePicker: false,
      title: "",
      urlComments: `https://balitripcenter.com/api/activity/${this.post.id}/comment`,
      body: "",
      rules: [(v) => !!v || this.$t("this-field-is-required")],
      starRating: 0,
      images: [],
      imagePreviews: [],
    };
  },
  created() {},
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    deleteImage(index) {
      this.imagePreviews.splice(index, 1);
      this.images.splice(index, 1);
    },
    previewImages(event) {
      this.imagePreviews = [];
      this.images = Array.from(event.target.files);
      this.images.forEach((image) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviews.push(e.target.result);
        };
        reader.readAsDataURL(image);
      });
    },
    postComment() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        axios.get(`https://balitripcenter.com/sanctum/csrf-cookie`).then(() => {
          const formData = new FormData();
          formData.append("name", this.name);
          formData.append("date", this.date);
          formData.append("rating", this.starRating);
          formData.append("title", this.title);
          formData.append("user_id", this.$store.getters["getProfile"].id);
          formData.append("body", this.body);
          this.images.forEach((image, index) => {
            formData.append(`images[]`, image);
          });

          axios
            .post(this.urlComments, formData, {
              headers: {
                Authorization: "Bearer " + this.$store.getters["getToken"],
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.submitLoading = false;
              this.$emit("commentSuccess");
              this.resetComment();
            })
            .catch(() => {
              this.submitLoading = false;
            });
        });
      }
    },
    cancelComment() {
      this.$emit("cancelDialog");
      this.resetComment();
    },
    resetComment() {
      this.name = "";
      this.date = "";
      this.title = "";
      this.body = "";
      this.valid = false;
      this.starRating = 0;
      this.images = [];
      this.imagePreviews = [];
    },
  },
};
</script>

<style scoped>
.custom-input {
  margin-bottom: 15px;
}
.custom-input label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-custom2 {
  height: 132px;
  width: 100%;
  scroll-snap-align: center;
  flex: 0 0 auto;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 20px;
  padding: 12.5px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #d9d9d9;
}

.card-img-custom {
  border-radius: 20px;
  min-width: 123px;
  min-height: 107px;
  width: 123px;
  max-width: 123px;
  height: 107px;
  object-fit: cover;
}

.custom-input input,
.custom-input textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #d9d9d9;
  border-radius: 5px;
  font-size: 16px;
}
.date-picker {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #d9d9d9;
  border-radius: 5px;
}
.file-input {
  display: flex;
  max-width: max-content;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #d9d9d9;
  border-radius: 5px;
}
.file-input input {
  display: none; /* Hides the default input */
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
</style>
